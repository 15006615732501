import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import CustomLink from './customLink'
import CtaButton from './ctaButton'
import Logo from "../assets/svg/logo.svg";
import LogoMin from "../assets/svg/logoMin.svg";
import ScrollHandler from "../hooks/scrollHandler"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const navThreshold = 20;

  return (
    <nav className={`flex flex-wrap fixed justify-center w-full h-[80px] z-[200] navBar px-[5%]  animate__animated animate__fadeInDown  ${ScrollHandler()>navThreshold?'scrolledNavBar':''}  `}>
      <span className="max-w-5xl flex w-full h-full ">
        <span className={`lg:w-[50%] w-[30%] flex items-center  ${ScrollHandler()>navThreshold?'hidden':'block'}`}>
          <Logo />
        </span>
        <span className={`lg:w-[50%] w-[30%] flex items-center  ${ScrollHandler()>navThreshold?'block':'hidden'}`}>
          <LogoMin />
        </span>


        <span className="items-center   lg:w-[50%] w-[80%] flex md:justify-around justify-end "> 
          <CustomLink text='home' href='/#home' title='Home' className={'hidden md:flex'}/> 
          <CustomLink text='about' href='/#about' title='About' className={'hidden md:flex'}/> 
          <CustomLink text='how it works' href='/#howitworks' title='How it works' className={'hidden md:flex'}/> 
          {/* <CustomLink text='contact' href='/#contact' title='Contact'/>  */}
          <CtaButton scrolled={ScrollHandler()>navThreshold?true:false} text='GET QUOTE' href='/#contact' title='contact'/>
        </span>
      </span>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
